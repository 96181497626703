import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from 'gatsby-link'
import { rhythm } from "../utils/typography"
import { Breadcrumb, Container, Row, Col, Button } from "react-bootstrap"
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import Hero from '../components/hero'

const StyledHero = styled(BackgroundImage)`
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    h1 {
      text-align: center;
      vertical-align: middle;
      color: var(--textHero);
      font-weight: bold;
      font-size: 6rem;
      padding: 3rem;
      font-family: 'Avenir Next','Helvetica Neue','Segoe UI','Helvetica','Arial',sans-serif;
      text-shadow: 2px 2px #000000;
      @media(max-width: 768px) {
        font-size: 3.5rem;
      }
    }
`

class ProjectPageTemplate extends React.Component {
  render() {
    const project = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    let fellArea = project.frontmatter.fellArea

    if (fellArea === 'N') {fellArea = 'Northern Fells'}
    if (fellArea === 'S') {fellArea = 'Southern Fells'}
    if (fellArea === 'W') {fellArea = 'Western Fells'}
    if (fellArea === 'E') {fellArea = 'Eastern Fells'}
    if (fellArea === 'NW') {fellArea = 'North Western Fells'}
    if (fellArea === 'C') {fellArea = 'Central Fells'}
    if (fellArea === 'FE') {fellArea = 'Far Eastern Fells'}

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={project.frontmatter.title}
          description={project.frontmatter.description || project.excerpt}
        />
        <div className="hero-container">
          <StyledHero
          Tag="section"
          fluid={project.frontmatter.image.childImageSharp.fluid}
          backgroundColor={`#040e18`}
          height="600px"
          >
          <Hero title={project.frontmatter.title} text={fellArea} />
          </StyledHero>
        </div>
        <Container>
          <Row>
          {previous && (
          <Col md={3} sm={6} xs={6}>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/" + previous.frontmatter.type + previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            )}
            { !previous &&  (
              <Col md={3}>
              </Col>
            )}
            <Col className="no-mobile" md={6} sm={0} xs={0}>
              <Breadcrumb>
                <Breadcrumb.Item href="/">{siteTitle}</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={"/" + project.frontmatter.type}>
                    {project.frontmatter.type.charAt(0).toUpperCase() + project.frontmatter.type.slice(1)}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {project.frontmatter.title}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col md={3} sm={6} xs={6}>
            {next && (
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/" + next.frontmatter.type + next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12} xs={12}>
                {
                project.frontmatter.details ?
                  project.frontmatter.details.map((detail) => {
                    return(
                      <div key={detail.name} className="detail">
                        <p><b>{detail.name}:</b> {detail.value}</p>
                      </div>
                    )
                  }
                  )
                  :
                  ''
                }
            </Col>
            <Col md={12}>
              <div dangerouslySetInnerHTML={{ __html: project.html }} />
              <Button
                block
                variant="secondary"
                key={project.frontmatter.link}
                href={project.frontmatter.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {project.frontmatter.linkName}
              </Button>
              <hr
                style={{
                  marginBottom: rhythm(1),
                }}
              />
            </Col>
          </Row>
          <Row>
              <Col md={6} sm={6} xs={6}>
                {previous && (
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to={"/" + previous.frontmatter.type + previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                )}
              </Col>
              <Col md={6} sm={6} xs={6}>
                {next && (
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to={"/" + next.frontmatter.type + next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  )}
              </Col>
              <Col>
                <p className="text-center">
                  <a href={project.frontmatter.imageAttribution}>
                      Image source
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
      </Layout>
    )
  }
}

export default ProjectPageTemplate

export const pageQuery = graphql`
  query ProjectPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        fellArea
        imageAttribution
        details {
          name
          value
        }
        type
        link
        linkName
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
